import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   meta: { isLogin: true },// 需要守卫
  //   component: () => import('../views/Home.vue')
  // },
  {
    path: '/login',
    name: 'login',
    meta: { isLogin: false },// 无需守卫
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'userlist',
    meta: { isLogin: true },
    component: () => import('../views')
  },
  {
    path: '/trial',
    name: 'trial',
    meta: { isLogin: true },
    component: () => import('../views/trial.vue')
  },
  {
    path: '/formal',
    name: 'formal',
    meta: { isLogin: true },
    component: () => import('../views/formal.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    meta: { isLogin: true },
    component: () => import('../views/userinfo.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: { isLogin: true },
    component: () => import('../views/auth.vue')
  },
  {
    path: '/authManagement',
    meta: { isLogin: true },
    component: () => import('../views/authManagement'),
    children:[
      {
        path: '',
        name: 'role_dispense',
        meta: { isLogin: true },
        component: () => import('../views/authManagement/role_dispense.vue')
      },
      {
        path: 'permissions',
        name: 'permissions',
        meta: { isLogin: true },
        component: () => import('../views/authManagement/permissions.vue')
      },
    ]
  },
  {
    path: '/article_list',
    name: 'article_list',
    meta: { isLogin: true },
    component: () => import('../views/article/list.vue')
  },
  {
    path: '/article_editor',
    name: 'article_editor',
    meta: { isLogin: true },
    component: () => import('../views/article/editor.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  mode:'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta);
  // console.log(to.fullPath);
  next();
  // 判断即将进入的路由对象是否需要守卫
  if (to.meta.isLogin) { // 需要守卫
    // 判断当前用户的登陆状态  登陆 直接查看next()  未登录  拦截到登陆页
    if (getCookie('admin_token')) { // 登陆凭证token
      next();
    } else { // 未登录
      next({
        path: '/login',
        query: {
          topath: to.fullPath
        }
      });
    }
  } else {
    next(); // 不需要守卫，接着执行
  }
})
function getCookie(cookieName) {
  const value = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : '';
}

export default router
